<template>
  <section>
    <!-- <upload-sheets
      :url_path="`contract/sheet/upload`"
      :showModal="showModalUpload1"
      @cancel="
        () => {
          showModalUpload1 = false;
        }
      "
      @done="getContractsList"
    />
    <upload-sheets
      :url_path="`contract/sheet/trl/upload`"
      :showModal="showModalUpload2"
      @cancel="
        () => {
          showModalUpload2 = false;
        }
      "
      @done="getContractsList"
    />
    <upload-sheets
      :url_path="`contract/sheet/revenue-share/upload`"
      :showModal="showModalUpload3"
      @cancel="
        () => {
          showModalUpload3 = false;
        }
      "
      @done="getContractsList"
    /> -->

    <b-modal ref="editTransactionsModal" hide-footer size="xl">
      <edit-transactions :contract_id="selectedContractId" />
    </b-modal>

    <!-- <b-row>
      <b-col
        class="d-flex align-items-end justify-content-between justify-content-sm-between"
      >
        <b-form-checkbox
          name="check-button"
          class="mb-1"
          inline
          v-model="showFilters"
          @input="
            (val) => {
              showFilters = val;
            }
          "
        >
          <b><u>Show Search Filters</u></b>
        </b-form-checkbox>
      </b-col>
    </b-row> -->

    <!-- Filters Card -->
    <app-collapse class="collapse-group" accordion type="border" style="margin-bottom: 20px">
      <app-collapse-item title="Filters ">
        <b-row>
          <b-col sm="12" md="8" lg="8">
            <b-input-group class="input-group-merge mb-1">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" v-model="filters.search" />
            </b-input-group>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.status"
              :reduce="(status) => status.value"
              :options="contract_statuses"
              aria-placeholder="Contract Status"
              placeholder="Contract Status"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.contract_nature"
              :options="contract_natures"
              aria-placeholder="Contract Nature"
              placeholder="Nature of Contract"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.movie"
              :options="$store.state.master.movies"
              :filter-by="movieFilter"
              label="name"
              :reduce="(movie) => movie.id"
              placeholder="Movie"
            >
            </v-select>
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <v-select
              class="mb-1"
              v-model="filters.vendor"
              :options="$store.state.master.vendors"
              label="company"
              :reduce="(vendor) => vendor.vendor_id"
              placeholder="Customer"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              placeholder="From Date"
              class="form-control mb-1"
              v-model="filters.from"
            />
          </b-col>
          <b-col sm="12" md="2" lg="2">
            <flat-pickr
              :config="{
                ...$flatPickrConfig,
              }"
              placeholder="To Date"
              class="form-control mb-1"
              v-model="filters.to"
            />
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              variant="primary"
              class="w-100 mb-1"
              @click="onFiltersChange"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              >Search</b-button
            >
          </b-col>
          <b-col sm="6" md="2" lg="2">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="w-100 mb-1"
              @click="clearFilters"
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row class="mb-2 mr-1">
      <!-- <b-col>
        <v-select
          @option:selected="onFiltersChange"
          v-model="pagination.limit"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="pagination.perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mb-1 mb-md-0"
        />
        <label class="mx-50">entries per page</label>
      </b-col> -->
      <b-col>
        <b-badge variant="info"
          >Total : {{ this.pagination.totalRows }}</b-badge
        >
      </b-col>

      <b-col class="d-flex align-items-end justify-content-end">
        <b-button-group class="mb-1">
          <b-dropdown variant="outline-info" right text="Export Contracts">
            <b-dropdown-item @click="downloadContractsCSV"
              >Contracts</b-dropdown-item
            >
            <b-dropdown-item @click="downloadTrlCSV"
              >Rights Territory Languages</b-dropdown-item
            >
            <b-dropdown-item @click="downloadRevenueShareCSV"
              >Revenue Share</b-dropdown-item
            >
          </b-dropdown>
          <b-button
            v-if="$can('create', 'contract')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="gradient-primary"
            @click="
              () => {
                $router.push('/contracts/form/new');
              }
            "
            >NEW CONTRACT</b-button
          >
        </b-button-group>
      </b-col>
    </b-row>

    <!-- Contracts List Table -->
    <b-card no-body v-if="$can('read', 'contract')">
      <b-table
        small
        bordered
        class="position-relative"
        primary-key="contract_id"
        responsive
        :empty-text="tableEmptyText"
        show-empty
        :items="contractsList"
        :fields="fields"
        hover
      >
        <!-- hover -->
        <template #cell(sr)="data">
          {{ data.index + 1 + (pagination.currentPage - 1) * pagination.limit }}
        </template>
        <template #cell(company)="data">
          <div style="width: max-content">
            <b-badge variant="light-primary"
              >{{ `#C${data.item.contract_nature[0]}${data.item.contract_id}` }}
            </b-badge>
            <br />
            <b-badge variant="light-primary">
              {{ data.item.contract_nature
              }}{{ data.item.draft ? "(draft)" : "" }}</b-badge
            ><br />{{ data.item.company }}
          </div>
        </template>
        <template #cell(title)="data">
          <div style="width: max-content" v-html="data.value"></div>
        </template>
        <template #cell(territory)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(right)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(language)="data">
          <span>
            {{ data.value }}
          </span>
        </template>
        <template #cell(purchaseDate)="data">
          <b-badge variant="light-info"
            >Contract Date : {{ data.item.purchaseDate }} </b-badge
          ><br />
          <b-badge variant="light-success" class="mt-1">
            Start : {{ data.item.rStart }} </b-badge
          ><br />

          <b-badge variant="light-danger" class="mt-1">
            End : {{ data.item.rExpiry }}</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="$can('read', 'contract')"
              :id="`contract-row-${data.item.contract_id}-view-icon`"
              icon="EyeIcon"
              size="16"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="View"
              class="mx-1 cursor-pointer"
              @click.stop="
                $router.push(`/contracts/details/${data.item.contract_id}`)
              "
            />
            <!-- "/contracts/details/ -->
            <feather-icon
              v-if="$can('update', 'contract') && !data.item.deleted"
              size="16"
              icon="EditIcon"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Edit"
              class="mr-1 cursor-pointer"
              @click.stop="
                $router.push(`/contracts/form/${data.item.contract_id}`)
              "
            />
            <feather-icon
              v-if="$can('create', 'contract') || $can('update', 'contract')"
              :id="`contract-row-${data.item.contract_id}-copy-icon`"
              icon="CopyIcon"
              size="16"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Copy"
              class="mr-1 cursor-pointer"
              @click.stop="
                $router.push(`/contracts/form/${data.item.contract_id}?copy=1`)
              "
            />
            <feather-icon
              v-if="
                ($can('create', 'contract') || $can('update', 'contract')) &&
                !data.item.deleted
              "
              :id="`contract-row-${data.item.contract_id}-list-icon`"
              icon="ListIcon"
              size="16"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Contract Transactions"
              class="mr-1 cursor-pointer"
              @click.stop="editTransactions(data.item.contract_id)"
            />
            <feather-icon
              v-if="$can('delete', 'contract') && !data.item.deleted"
              :id="`contract-row-${data.item.contract_id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Delete Contract"
              class="mr-1 cursor-pointer"
              @click.stop="
                () => {
                  showModal(
                    'Are you sure want to delete this contract?',
                    'delete'
                  ).then((value) => {
                    if (value) deleteContract(data.item.contract_id);
                  });
                }
              "
            />
          </div>
        </template>
        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>
      </b-table>

      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BBadge,
  BTable,
  BCol,
  BRow,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BPagination,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BModal,
  VBTooltip,
  BFormCheckbox,
  BButtonGroup,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import * as ContractServices from "@/apiServices/ContractServices";
import EditTransactions from "./EditTransactions.vue";
import { contract_natures, labels, contract_statuses } from "@/utils/constants";
import Treeselect from "@riophae/vue-treeselect";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import showModal from "../../components/ModalConfirmation";
import { TokenService } from "@/apiServices/storageService";
import { downloadFromURL } from "@/utils/helpers";
import UploadSheets from "@/views/components/UploadSheets.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    BCard,
    BBadge,
    BTable,
    BCol,
    BRow,
    BButton,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BPagination,
    FeatherIcon,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BTooltip,
    BModal,
    EditTransactions,
    vSelect,
    Treeselect,
    flatPickr,
    BButtonGroup,
    UploadSheets,
    AppCollapseItem,
    AppCollapse
  },
  data() {
    return {
      showModalUpload1: false,
      showModalUpload2: false,
      showModalUpload3: false,

      tableEmptyText: "LOADING...",

      contract_natures,
      contract_statuses,
      // showFilters: true,
      filters: {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        // territories: [],
        // rights: [],
        // languages: [],
        from: "",
        to: "",
      },

      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100, "All"],
      },
      fields: [
        {
          key: "actions",
          label: "Actions",
          stickyColumn: true,
          variant: "primary",
        },
        {
          key: "sr",
          label: "SR",
          // sortable:true,
        },
        {
          key: "company",
          label: "Contact",
        },
        {
          key: "title",
          label: "Movie",
        },
        {
          key: "territory",
          label: "territory",
        },
        {
          key: "right",
          label: "rights",
        },
        {
          key: "language",
          label: "languages",
        },
        {
          key: "purchaseDate",
          label: "contract dates",
        },
        // {
        //   key: "rStart",
        //   label: "start",
        // },
        // {
        //   key: "rExpiry",
        //   label: "end date",
        // },
        {
          key: "language",
          label: "languages",
        },
      ],
      contractsList: [],
      selectedContractId: null,
    };
  },
  methods: {
    showModal,
    uploadContractsExcel() {
      try {
      } catch (error) {
        console.error(`Error in uploadContractsExcel `, error);
      }
    },
    uploadTrlExcel() {
      try {
      } catch (error) {
        console.error(`Error in uploadTrlExcel `, error);
      }
    },
    uploadRevenueShareExcel() {
      try {
      } catch (error) {
        console.error(`Error in uploadRevenueShareExcel `, error);
      }
    },
    downloadContractsCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/contract/sheet/download?authorization=${token}`;

        downloadFromURL(urlStr, "Contracts", "csv");
      } catch (error) {
        console.error(`Error in downloadContractsCSV `, error);
      }
    },
    downloadTrlCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/contract/sheet/trl/download?authorization=${token}`;

        downloadFromURL(urlStr, "ContractRights", "csv");
      } catch (error) {
        console.error(`Error in downloadContractsCSV `, error);
      }
    },
    downloadRevenueShareCSV() {
      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        let urlStr = `${process.env.VUE_APP_BASEURL}/contract/sheet/revenue-share/download?authorization=${token}`;

        downloadFromURL(urlStr, "ContractRevenue", "csv");
      } catch (error) {
        console.error(`Error in downloadContractsCSV `, error);
      }
    },
    movieFilter: (option, label, search) => {
      let temp = search.toLowerCase();
      return (
        option.name && option.name.toString().toLowerCase().indexOf(temp) > -1
      );
    },
    clearFilters() {
      this.filters = {
        search: "",
        status: "",
        contract_nature: "",
        vendor: "",
        movie: "",
        territories: [],
        rights: [],
        languages: [],
        from: "",
        to: "",
      };
      this.pagination = {
        ...this.pagination,
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      };
      this.onChange();
    },
    onFiltersChange() {
      this.pagination.currentPage = 1;
      this.onChange();
    },
    rowClicked(item, index, event) {
      this.$router.push(`/contracts/details/${item.contract_uuid}`);
    },
    editTransactions(contract_id) {
      this.selectedContractId = contract_id;
      this.$refs["editTransactionsModal"].show();
    },
    async deleteContract(contract_id) {
      try {
        const res = await ContractServices.setContractDelete({
          contract_id,
        });

        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.onChange();
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Failed",
              icon: "EditIcon",
              variant: "primary",
            },
          });
        }
      } catch (error) {
        console.log("Error in deleting contract ", error);
      }
    },
    async getContractsList() {
      const loader = this.$loading.show();

      try {
        const response = await ContractServices.getAllContract({
          limit: this.pagination.limit,
          page: this.pagination.currentPage,
          ...this.filters,
        });

        this.contractsList = response.data.data.contracts;
        if (!isNaN(response.data.data.totalRows)) {
          this.pagination.totalRows = response.data.data.totalRows;
        }
        if (!this.contractsList.length)
          this.tableEmptyText = "NO RECORDS FOUND";
      } catch (error) {
        console.log("Error in getting Contracts List");
        this.tableEmptyText = "Error in getting contracts";
      } finally {
        loader.hide();
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getContractsList();
        localStorage.setItem(
          "filtersAndPaginationContracts",
          JSON.stringify({
            filters: this.filters,
            pagination: this.pagination,
          })
        );
      });
    },
    loadContractList() {
      const filtersAndPaginationContractsStr = localStorage.getItem(
        "filtersAndPaginationContracts"
      );
      if (filtersAndPaginationContractsStr) {
        const obj = JSON.parse(filtersAndPaginationContractsStr);
        this.pagination = obj.pagination;
        this.filters = obj.filters;
        this.$forceUpdate();
      }
      this.getContractsList();
    },
  },
  mounted() {
    this.loadContractList();

    this.$store.dispatch("master/setVendorsMaster");
    this.$store.dispatch("master/setMoviesMaster");

    this.$store.dispatch("master/setLabelMaster", { key: labels.language });

    // Tree Data
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
  },
};
</script>

<style></style>
